import React from 'react'
import {useEffect,useState, useRef} from 'react';
import './menu.scss'
import {/*animateDivScroll,*/useEvent/*,elementVisible*/} from './functions'
var classNames = require('classnames');
function Menu(props)
{
function hideBg(e)
{
setShowMobile(false)
}
function open(item) 
{
setShowMobile(false)
//window.location.hash='#'+item
window.history.replaceState(null, null, document.location.pathname+'#'+item);
window.scrollTo({top: document.getElementById(item).offsetTop-80,behavior: "smooth"})
}
function checkScroll() //подсвечивает пункт меню, добавляет фон меню при скролле
{
setMenuFix((window.scrollY!==0))
const menu=document.getElementsByClassName('menu_item')
  if (typeof menu!=='undefined') 
   {
let curIt;
    for (let i = 0; i < menu.length; i++) menu[i].classList.remove("menu_item_act");
    if (window.scrollY===0) 
     {
menu[0].classList.add("menu_item_act");
return true;
     }
    for (let i = menu.length-1; i >=0 ; i--)
     {
menu[i].classList.remove("menu_item_act")
curIt=document.getElementById(menu[i].dataset.key)
if (curIt && window.scrollY+80+window.innerHeight*0.5>curIt.offsetTop) {menu[i].classList.add("menu_item_act"); break}
    //if (elementVisible(menu[i].dataset.key)) menu[i].classList.add("menu_item_act")
     }
   }
}
const arr=[
{name:'Сезон',a:'season'},
{name:'Монеты',a:'monets'},
{name:'Стор',a:'store'},
{name:'Обмен',a:'change'},
{name:'Скачать',a:'download'}
];
//const [scroll,setScroll]=useState(0)
const [menuFix,setMenuFix]=useState(false)
const [showMobile,setShowMobile]=useState(false)
//need to detect mobile /disable scroll
const [size,setSize]=useState({w:0,h:0})
const menuRef=useRef(null)
const i_resize=(e)=>
{
const wh=window.innerHeight, ww=window.innerWidth
setSize({w:ww,h:wh})
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => {
i_resize()
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
//
useEvent('scroll',checkScroll)
useEvent('load',checkScroll)
useEffect(() => { //disable scroll when mobile menu appears
const  ww=window.innerWidth;
//анимации для меню, с целью исправить баг видимости меню при ресайзе десктоп-мобилка
  //if (ww<=991) menuRef.current.classList.add("menu_wrapper_mobile");
  //else menuRef.current.classList.remove("menu_wrapper_mobile");
  if (ww<991)  menuRef.current.style.transition='1s all linear'
  else menuRef.current.style.transition=''
//
  if (showMobile && ww<=991) document.body.classList.add("disable_scroll");
  else document.body.classList.remove("disable_scroll");
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[showMobile,size.w]);
  return (
<div className={classNames("menu",{"menu_sticky":menuFix})}>
<img alt="Protone" src="./i/logo.svg" className="logo" />
	<div ref={menuRef}className={classNames("menu_wrapper",{"menu_wrapper_show":showMobile},{"menu_wrapper_mobile":showMobile})} onClick={()=>hideBg()}>{arr.map((it,i)=><div className="menu_item" onClick={()=>open(it.a)} key={i} id={'menu_'+it.a} data-key={it.a}>{it.name}</div>)}</div>
<div className="menu_button" onClick={()=>setShowMobile(!showMobile)}><img src="./i/menu.svg" alt="" /></div>
</div>
  );
}

export default Menu;