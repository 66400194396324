import './App.scss';
import {useState,useEffect,useRef,useMemo,memo} from 'react';
import {useEvent} from './functions.js'
import Menu from "./menu"
import Slider from "./slider"
var classNames = require('classnames');

function App() 
{
const [size,setSize]=useState({w:0,prevState:{w:-1}})
const refSize=useRef(null)
refSize.current=size;
const i_resize=(e)=>
{
	window.setTimeout(function(){
const ww=window.innerWidth
	  if (typeof size.prevState==='undefined') return;
const breakpoints=[0,600,991,1922,90000] //указываем какие у нас есть брикпоинты, 0 - обязательно, 90000 тоже - нижняя и верхняя границы соотвественно
const minA=breakpoints.find((el) => el<ww) //значение меньше текущей ширины в массиве
const maxA=breakpoints.find((el) => el>=ww) //значение больше текущей ширины в массиве
const minPrevA=breakpoints.find((el) => el<refSize.current.prevState.w) //значение меньше текущей ширины в массиве для предыдущей ширины
const maxPrevA=breakpoints.find((el) => el>=refSize.current.prevState.w) //значение больше текущей ширины в массиве  для предыдущей ширины
 //мы по прежнему в текущих границах респонсива, обновлять не нужно, 
//иначе будет реинит компоненты, memo жрет очень много, если в него все обернуть. это решение лучше
  if (minA===minPrevA && maxA===maxPrevA) return;
 //console.log(minA,minPrevA,maxA,maxPrevA,'current',ww,refSize.current.prevState.w)
 //нас интересует только изменения: >991 либо <600, остальное отбрасываем, иначе будет реинит компоненты, memo жрет очень много, если в него все обернуть. это решение лучше
 // if (ww===refSize.current.prevState.w || (refSize.current.prevState.w>992 && ww>992) || (refSize.current.prevState.w>601 && ww<990 && ww>601) || (refSize.current.prevState.w<601 && ww<601) ) return;
setSize({w:ww,prevState:{w:ww}}) /*refSize.current.w*/
},10)
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => {
i_resize()
  if (window.location.hash!=='') 
{
//исправляет баг некоторых браузеров по работе хеша и реакта
	window.setTimeout(()=>{
const cur=window.location.hash
window.location.hash=''
window.location.hash=cur
  },30)
}	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);

//for store
/*
const cards=[
{title:"Protone<br /><span class='green'>X</span> Настя Миро",subtitle:"1 200",bottomtitle:"3D-копия осьминога<br />известной художницы.<br />Оригинал улетит<br />в космос!<div class='title_bottom_out'>количество ограничено</div>",bg:"store_octopus.png",big:false},
//{title:"grounded<br />ultra",subtitle:"2 000",bottomtitle:"Билет<br />на фестиваль<br />уличной культуры<div class='title_bottom_out'>количество ограничено</div>",bg:"store_grounded.png",big:false},
{title:"МНОГОРАЗОВАЯ<br />бутылка",subtitle:"2 000",bottomtitle:"Сокращай<br />использование<br />пластика стильно",bg:"store_bottle.png",big:false},
//{title:"сертификат<br />литрес",subtitle:"1 000",bottomtitle:"1000р<br />на любые книги",bg:"store_litres.png",big:false},
{title:"яндекс<br />плюс",subtitle:"1 500",bottomtitle:"<strong>3 месяца</strong><br />Музыка, фильмы,<br />кешбэк баллами",bg:"store_yandex.png",big:false},
{title:"телеграм<br />премиум",subtitle:"2 000",bottomtitle:"<strong>3 месяца</strong><br />Расшифровка войсов, увеличенные лимиты, анимированные эмодзи и многое другое",bg:"store_telegram.png",big:false},
{title:"офигенные<br />худи",subtitle:"9 000",bottomtitle:"Лимитный мерч <br />от наших дизайнеров",bg:"store_hudi.png",big:false},
{title:"эко-стеллаж<br />для зелени",subtitle:"15 000",bottomtitle:"<br />Собственная<br />сити-ферма<br />для выращивания<br />зелени и овощей<br /><br />Просто добавь воды!",bg:"store_stellage.png",big:true},
{title:"AirPods<br />Pro 2",subtitle:"42 000",bottomtitle:"Минимум шума,<br />максимум стиля",bg:"store_airpods.png",big:true},
{title:"Signal<br />Ticket",subtitle:"12 000",bottomtitle:"15-19.08<br />Никола-Ленивец,<br />летняя тусовка года",bg:"store_ticket.png",big:false},
{title:"Signal<br />Priority",subtitle:"25 000",bottomtitle:"VIP доступ,<br />бэкстейдж на сцены,<br />бесплатный шаттл",bg:"store_priotity.png",big:false},
]*/
const cards=[
{title:"Protone<br /><span class='green'>X</span> Настя Миро",subtitle:"1 200",bottomtitle:"3D-копия осьминога<br />известной художницы.<br />Оригинал улетит<br />в космос!<div class='title_bottom_out'>количество ограничено</div>",bg:"store_octopus.png",big:false},
/*{title:"grounded<br />ultra",subtitle:"2 000",bottomtitle:"Билет<br />на фестиваль<br />уличной культуры<div class='title_bottom_out'>количество ограничено</div>",bg:"store_grounded.png",big:false},*/
{title:"МНОГОРАЗОВАЯ<br />бутылка",subtitle:"2 000",bottomtitle:"Сокращай<br />использование<br />пластика стильно",bg:"store_bottle.png",big:false},
/*{title:"сертификат<br />литрес",subtitle:"1 000",bottomtitle:"1000р<br />на любые книги",bg:"store_litres.png",big:false},*/
{title:"яндекс<br />плюс",subtitle:"sold out",bottomtitle:"<strong>3 месяца</strong><br />Музыка, фильмы,<br />кешбэк баллами",bg:"store_yandex.png",big:false},
{title:"телеграм<br />премиум",subtitle:"sold out",bottomtitle:"<strong>3 месяца</strong><br />Расшифровка войсов, увеличенные лимиты, анимированные эмодзи и многое другое",bg:"store_telegram.png",big:false},
{title:"офигенные<br />худи",subtitle:"9 000",bottomtitle:"Лимитный мерч <br />от наших дизайнеров",bg:"store_hudi.png",big:false},
{title:"эко-стеллаж<br />для зелени",subtitle:"15 000",bottomtitle:"<br />Собственная<br />сити-ферма<br />для выращивания<br />зелени и овощей<br /><br />Просто добавь воды!",bg:"store_stellage.png",big:true},
{title:"AirPods<br />Pro 2",subtitle:"42 000",bottomtitle:"Минимум шума,<br />максимум стиля",bg:"store_airpods.png",big:true},
{title:"Signal<br />Ticket",subtitle:"12 000",bottomtitle:"15-19.08<br />Никола-Ленивец,<br />летняя тусовка года",bg:"store_ticket.png",big:false},
{title:"Signal<br />Priority",subtitle:"25 000",bottomtitle:"VIP доступ,<br />бэкстейдж на сцены,<br />бесплатный шаттл",bg:"store_priotity.png",big:false},
]
useEffect(() => {
//preload cards
let preload=[]
  for (var i=0; i<cards.length; i++) 
   {
let item=new Image();
item.src='./i/'+cards[i].bg;
preload.push(item)
   }
//end of preload	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);

  return (
    <div className="App">
<Menu />
<div name="season" aria-label="season"  aria-hidden="true" />
<Head size={size} />
<Download1 size={size} />
<div name="monets" aria-label="missions" aria-hidden="true" />
<Missions />
<div name="store" aria-label="store" aria-hidden="true" />
<Store bigsize={size.w>600} cards={cards}   />
<Change bigsize={size.w>1920} />
<Footer />
    </div>
  );
}

const Head=memo((props)=>
{
//<div className="column_2"><img src={(ww>=601)?"./i/main_bg.png":"./i/main_bg_mob.png"} alt=""  /></div>
//<div className="column_2"><img src="./i/main_bg.png" alt=""  /></div>
//const ww=parseInt(props.size.w)
return (
<div className="head_block" id="season">
<div className="head_bg"></div>
	<div className="head_block_1">
		<div className="title">лето с protone</div>
		{/*<img src="./i/main_1_leto.png" alt="" className="head_block_text_img" />
<img src="./i/main_2_leto.png" alt="" className="head_block_text_img" />*/}
		<div className="head_block_1_wrapper">
<div className="small_image column_1">
	<div><img src="./i/main_location.png" alt="" /></div>
	<div className="title">Новые<br />локации</div>
</div>
<div className="column_2"><img src="./i/main_bg.png" alt=""  /></div>
<div className="small_image column_3">
	<div className="title">Аутдор<br />активности</div>
	<div><img src="./i/main_activity.png" alt="" /></div>
</div>
		</div>
	</div>
	<div className="head_block_2">
<div className="text">Protone — игра, в которой пользователи делают <span>окружающий мир</span> лучше,
<br />выполняя <span>увлекательные миссии</span> внутри тематических локаций</div>
	</div>
</div>
)
})
	
const Download1=(props)=>
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
const ww=parseInt(props.size.w)
return(
<div className="download_1">
<img className="image" src="./i/protone_app_casual.png"  alt="Protone.app casual" onLoad={(e)=>e.target.style="opacity:1"} />
	<div className="button_wrapper">
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="button" style={{backgroundImage:'url("./i/apple.svg")'}}> </a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="button" style={{backgroundImage:'url("./i/google.svg")'}}> </a>
	</div>
<img className="glow"  src={(ww>=991)?"./i/glow_bg_app_store.svg":"./i/glow_protone_app_mobile.svg"}  alt="" />
<img className="mobile" src="./i/picture_phone.png"  alt="" onLoad={(e)=>e.target.style="opacity:1"} />
<img src="./i/qr.png" alt="" className="qr" />
</div>
)
}
		
const Missions=memo(()=>
{
return(
<div className="missions"  id="monets">
<img className="image missions_bg" alt="" src="./i/phone_1.png" onLoad={(e)=>e.target.style="opacity:1"} />
<img className="image missions_bg_bottom" alt="" src="./i/glow_bg_coins.svg" onLoad={(e)=>e.target.style="opacity:1"} />
	<h1 className="title">система<br />вознаграждений</h1>	
	<div className="text">В Protone ни одно дело не останется незамеченным.
<br />
<strong>Все действия</strong> пользователей оставляют цифровой след, <strong>приносят опыт и игровую валюту</strong> — протоны и атомы
	</div>
	<div className="missions_wrapper" >
		<div className="one">
<img className="image image_top" alt="" src="./i/protone_coin_1_small.png" onLoad={(e)=>e.target.style="opacity:1"} />
<h2 className="title">Протоны</h2>
<div className="text">— особые монеты, которые можно обменять на награды в реальном мире:
<br /><br />	
подписки на сервисы, лимитированный мерч и даже гаджеты</div>
<img className="image image_bottom" alt="" src="./i/protone_coin_1.png" onLoad={(e)=>e.target.style="opacity:1"} />
		</div>

		<div className="one">
<img className="image image_top" alt="" src="./i/protone_coin_2_small.png" onLoad={(e)=>e.target.style="opacity:1"} />
<h2 className="title">Атомы</h2>
<div className="text">— валюта, которую можно тратить внутри приложения. Например, на одежду и предметы для аватара.
<br /><br />			
Атомы можно получить, выполняя простые миссии в приложении</div>
<img className="image image_bottom" alt="" src="./i/protone_coin_2.png" onLoad={(e)=>e.target.style="opacity:1"} />
		</div>
	</div>
</div>	
)
})
	
const Store=(props)=>
{
const {cards}=props
if (cards==='undefined') return ''
	function StoreCard(props)
	{
const bg=(props.bigsize===false && props.big===true)?props.bg.replaceAll('.png','_small.png'):props.bg;
return(
<div className={classNames("store_card",{"store_card_big":(props.big && props.bigsize===true)})} style={{backgroundImage:'url("./i/'+bg+'")'}}>
	<div className="store_card_wrapper_top">
<div className="title" dangerouslySetInnerHTML={{__html: props.title}}></div>
<div className="subtitle"><img src="./i/store_card.svg" alt="" />{props.subtitle}</div>
	</div>
<div className="title_bottom" dangerouslySetInnerHTML={{__html: props.bottomtitle}}></div>
</div>
)
	}
	function StoreCards(props)
		{
const arr=useMemo(() =>cards.map((item,i)=><StoreCard key={i} title={item.title} subtitle={item.subtitle} bottomtitle={item.bottomtitle} big={item.big} bg={item.bg} bigsize={props.bigsize} />),[props.bigsize])
const slider=useMemo(() =><Slider arr={arr} autoplay={5000} />,[arr])
return(
<div className="store_cards">
{(props.bigsize===true)?arr:slider}
</div>
)
		}
//	function StoreImg(props)
//	{
//const imageRef=useRef(null)
/*
useEffect(() => {
let interval=window.setTimeout(()=>
	window.setInterval(()=>
	{
	if (typeof imageRef==='undefined' || imageRef.current===null ) return; 
	imageRef.current.classList.remove("image_animate");
	window.setTimeout(function(){
imageRef.current.classList.add("image_animate");console.log(props.img)},0)
	},props.timer),props.delay)
  return () => {
    clearTimeout(interval);
  };
}, []);*/
//return (
//<div className={"image"+props.cls} ref={imageRef}><img src={props.img} alt="" /></div>
//)
//	}

return(
<div className="store" id="store">
<img className="store_bg" src="./i/phone_2.png" alt="" />
	<div className="store_title">protone store</div>
	<div className="store_subtitle">Обменяй свои протоны<br />на <strong>крутые награды</strong></div>
<div className="wrapper_arrows">
	<div className="item">01</div>
	<img src="./i/arrow.svg" alt="" className="item" />
	<div className="item">02</div>
	<img src="./i/arrow.svg" alt="" className="item" />
	<div className="item">03</div>
</div>
	<div className="wrapper_top">
		<div className="one" style={{backgroundImage:'url("./i/store_01_bg.png")'}}>
<img className="image" alt="" src="./i/store_01.png" />
<div className="text"><strong>Проходи</strong> импакт миссии<br />в приложении Protone</div>
		</div>
		<div className="one" style={{backgroundImage:'url("./i/store_02_bg.png")'}}>
<img className="image" alt="" src="./i/store_02.png" />
<div className="text"><strong>Получай</strong> протоны</div>
		</div>
		<div className="one" style={{backgroundImage:'url("./i/store_03_bg.png")'}}>
<img className="image" alt="" src="./i/store_03.png" />
<div className="text"><strong>Накапливай</strong> или<br /><strong>обменивай</strong> на награды</div>
		</div>
{/*<div className="store_wrapper_end"></div>*/}
	</div>
	<div className="store_big_wrapper">
<div className="title_count">Количество наград<br />ограничено - успей ухватить</div>
<StoreCards cards={props.cards} bigsize={props.bigsize} />
	</div>
</div>	
)
}
//end of for store
	
const Change=memo((props)=>
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
let arr_slider=[]
arr_slider.push(<div key={1} className="change_download_text">Скачай приложение</div>)
arr_slider.push(<div key={2} className="change_download_text">Зарегистрируйся</div>)
arr_slider.push(<div key={3} className="change_download_text">Выполняй миссии</div>)
arr_slider.push(<div key={4} className="change_download_text">Накапливай очки</div>)
arr_slider.push(<div key={5} className="change_download_text"><span>Забирай заслуженные награды</span></div>)
return(
<div className="change_wrapper">
<div name="download" aria-hidden="true" id="change" />
<div className="change">
<h1 className="title_change">обмен протонов</h1>
<div className="text_change">Ниже мы оставили краткую инструкцию как поменять заработанные протоны на крутые призы!</div>
<a href="https://t.me/Protone_Store_Bot" target="_blank" rel="noreferrer" className="change_button">ОБМЕНЯТЬ<img src="./i/change.svg" className="image" alt="" /></a>
		<div className="change_big_wrapper">
	<div className="change_wrapper1">
		<div className="column1">
	<div className="top_symbol">
<div className="word">id</div>
<div className="word2">impact</div>
	</div>
<div className="text">Приготовь свой
<br /><span className="bold">никнейм и импакт ID</span>
<br />
<br />Всё найдёшь в нашем приложении
<br /><span className="bold">в разделе “ПРОФИЛЬ”</span>
</div>
		</div>
			<div className="column2">
<a href="https://t.me/Protone_Store_Bot" target="_blank" rel="noreferrer"><img src="./i/teleg_big.svg" alt="" className="image" /></a>
<div className="text">Напиши в наш телеграм-бот<div className="bold">“Хочу потратить протоны”</div></div>
<a href="https://t.me/Protone_Store_Bot" target="_blank" rel="noreferrer" className="button">@protone_store_bot</a>
			</div>
	</div>
	<div className="change_big"><img src={props.bigsize?"./i/change_bg_big.png":"./i/change_bg.png"} alt="" className="change_big_img" /></div>
		</div>

<div name="download" aria-hidden="true" id="download" />
<img src="./i/qr_big.png" className="change_qr" alt="" />
	<div className="change_download">
<Slider arr={arr_slider} autoplay={1500}  />
	</div>
<h1 className="change_play">Вступай в игру!</h1>
	<div className="change_button_wrapper">
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="button" style={{backgroundImage:'url("./i/apple.svg")'}}><span /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="button" style={{backgroundImage:'url("./i/google.svg")'}}><span /></a>
	</div>
</div></div>
)
})
	
const Footer=memo(()=>
{
return(
<div className="footer">
	<div className="footer_social">

<a href="https://t.me/protone" target="_blank" rel="noreferrer"><img src="./i/soc_teleg.png" className="img" alt="" /></a>
<a href="https://www.tiktok.com/@protone.app" target="_blank" rel="noreferrer"><img src="./i/soc_tiktok.png" className="img" alt="" /></a>
<a href="https://www.vk.com/protone_app" target="_blank" rel="noreferrer"><img src="./i/soc_vk.png" className="img" alt="" /></a>

	</div>
<div className="copy">©2024 - Protone  |   All rights reserved</div>
<a href="./eula_protone.pdf" className="copy2" target="_blank">Политика конфиденциальности</a>
</div>	
)
})

export default App;
